import React from 'react';
import { Link } from 'gatsby';

const BlogPostCard = ({
  className,
  featuredImage,
  link,
  title,
  date,
  excerpt,
}) => (
  <div className="card">
    <div class="card-image">
      <figure class="image">{featuredImage}</figure>
    </div>
    <div class="card-content">
      <Link className="title is-size-4 is-block" to={link}>
        {title}
      </Link>
      <div class="content">{excerpt}</div>

      <Link className="button" to={link}>
        Keep reading →
      </Link>
    </div>
  </div>
);

export default BlogPostCard;
